<template>
  <div class="Site PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('teamReport.default[0]')"
            left-arrow @click-right="onClickRight"
            @click-left="$router.go(-1)"
    >
      <div slot="right">
        <van-button size="min" style="width: 100px;height: 26px" @click="onClickRight">
          <span>{{$t('common7[5]')}}</span></van-button>
      </div>
    </van-nav-bar>
    <div class="Panel">
      <div class="Menu2item1">
        <div style="color: var(--font_subtitle)">{{$t('common7[6]')}}
        </div>
        <div style="font-weight: bold;font-size: 30px"> {{ teamCount }} </div>
      </div>
      <div class="Menu2item1">
        <div style="color: var(--font_subtitle)">{{$t('common7[7]')}}
        </div>
        <div style="font-weight: bold;font-size: 30px"> {{ monthNew }} </div>
      </div>
    </div>
    <div class="ScrollBox">
      <div v-if="listData.length==0" style="margin: 20px auto;margin-left: 20px">{{$t('vanPull[1]')}}</div>
      <div v-else>
        <div style="margin: 20px auto;margin-left: 20px">{{$t('team2[0]')}}</div>
        <div class="listitem" v-for="(item,index) in listData">
          <div class="robotheader">
            <img :src="'./static/head/'+item.header" />
          </div>
          <div style="width: 60%;margin-left: 20px">{{item.username}} </div>
          <div style="width: 20%;text-align: right;color: var(--font_subtitle)">VIP{{item.vip_level}} </div>
        </div>
        <div style="margin: 0px auto;text-align: center">{{$t('vanPull[0]')}}</div>
      </div>
      <div style="width: 90%;margin: 0 auto;text-align: center;margin-top: 30px">
      <van-button style="width:100%;height: 40px" to="/user/invite">
        <span>{{$t('team2[1]')}}</span></van-button>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'Service',
    components: {

    },
    props: {},
    data() {
      return {
        teamCount:0,
        monthNew:0,
        listData:[],
      };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
      this.getListData();
    },
    activated() {

    },
    destroyed() {

    },
    methods: {
      onClickRight(){
        var url = "/user/invite/"+this.UserInfo.idcode+"/"+this.UserInfo.idcode
        this.$router.push(url);
      },
      getListData(type) {
        var json = {};
        this.$Model.TeamReport(json,(data) => {
          this.isLoad = false;
          if (data.code == 1) {
            var users = data.data;
            // users =  [{header:"1",username:"aaa",vip_level:3},{header:"1",username:"aaa",vip_level:2}];
            this.monthNew = data.monthNew;
            this.teamCount = users.length
            users.forEach(uu=>{
              var ut = uu['user_type'];
              var utype = this.$t("busAuth3["+ut+"]")
              uu['u_type'] = utype
            })
            this.listData = users;
          } else {
            var testitems = [];
            this.listData = testitems;
            this.isFinished = true;
          }
        })
      }
    },
  }
</script>
<style scoped>
.PageBox {
  overflow: auto;
  padding-top: 60px;
  background: url(~@/assets/img/new/login_top.png) no-repeat;
  background-size:  100% auto;
}
  .PageBox >>> .van-nav-bar__title{
    color:#FFFFFF;
    font-size: 18px;
  }
  .PageBox >>> .van-nav-bar .van-nav-bar__arrow {
    font-size: 20px;
    color:#FFFFFF;
  }

  .Menu2item1 {
    margin-top: 10px;
    border-radius: 5px;

    line-height: 25px;
    margin-left: 10px;
    width: 46%;
    text-align: center;
    padding: 10px;
  }

  .ScrollBox{

  }
  .robotheader{
    font-size: 19px;
    border-radius: 50%;
    background: pink;
    overflow: hidden;
    width: 32px;
    height: 32px;
  }

  .robotheader img {
    width: 100%;
  }

  .listitem {
    display: flex;
    line-height: 30px;
    border-radius: 10px;
    margin: 10px auto;
    width: 95%;
    margin-top: 20px;
    background-color: var(--panel_color);
    padding: 10px;
    padding-left: 20px;
  }
</style>
