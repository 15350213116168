<template>
	<div class="PageBox">
		<van-nav-bar
				fixed
				:border="false"
				:title="$t('common6[0]')"
				left-arrow
				@click-left="$router.go(-1)"
		>
		</van-nav-bar>
		<div class="panel">
			<van-list v-model="isLoad" :finished="isFinished" @load="onLoad">
				<van-cell style="padding-top: 0px;padding-right: 0px"
						class="FundItem" :border="false"
						v-for="(item, index) in listData"
						:key="item.order_id">
					<template>
						<div style="color: var(--font_subtitle)">
							<div style="display: flex">
								<div style="display: flex;width: 70%;margin-top: 10px">
									<div style="margin-right: 5px">{{ item.dan }}</div>
								<van-icon name="./skinlscc/copy.png" class="copy-qb" @click="copyCode(item.dan)" size="18"/>
								</div>
								<div class="statusBlock" :style="'background:'+item.status_color">
									{{ item.status_desc }}
								</div>
							</div>
							<div style="color: var(--button_bg2);font-weight: bold">{{ parseFloat(item.money).toFixed(2) }}
							</div>
							<div style="display: flex">
								<div style="width: 70%">{{ item.adddate }}</div>
								<div></div>
							</div>
              <div v-if="item.remarks&&item.remarks.length>0">
                {{ item.remarks }}
              </div>
						</div>
					</template>
				</van-cell>
			</van-list>
      <textarea id="inputBox" style="position: absolute;top: 0;left: 0;opacity: 0;z-index: -10;"></textarea>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'Wallet',
		components: {

		},
		props: ['walletType','id'],
		data() {
			return {
				active:2,
				isLoad: false,//显示正在加载
				isFinished: false,//控制是否禁止触发上拉触底的事件
				pageNo:1,
				pagesize:20,
				listData:[],
				robotUserName:localStorage['robotUserName']||''
			}
		},
		created() {
			this.getListData("init");
		},
		mounted() {

		},
		methods: {
			copyCode(order_number) {
				var that = this;
        var copyBox = document.getElementById("inputBox");
        copyBox.value = order_number;
        copyBox.select();
        document.execCommand("Copy"); // 执行浏览器复制命令
        this.$Dialog.Toast(this.$t('recharge.label[5]'));

        // var copyValue = order_number;
				// let clipboard = new Clipboard('.copy-qb', {
				// 	text: () => {
				// 		return copyValue
				// 	}
				// })
				// clipboard.on('success', () => {
				// 	that.$Dialog.Toast(this.$t('recharge.label[5]'));
				// 	clipboard.destroy()
				// })
				// clipboard.on('error', () => {
				// 	that.$Dialog.Toast(this.$t('taskOrder2[3]'));
				// 	clipboard.destroy()
				// })
			},
			tabChanged(index){
				this.active = index;
				this.getListData("init");
			},
			//触底触发，页面初始化触发
			onLoad() {
				// 异步更新数据
				this.getListData("load");
			},
			//获取用户历史记录
			getListData(type) {
				this.isLoad = true;
				this.isRefresh = false;
				if (type == "load") {
					this.pageNo += 1;
				} else {
					this.pageNo = 1;
					this.isFinished = false;
				}
				let json={
					task_type: this.active,
					page_no: this.pageNo,
					is_u:1
				}
				this.pages++
				this.$Model.GetRechargeRecord(json,
						(data) => {
					this.isLoad = false;
					if (data.code == 1) {
						if (type == "load") {
							this.listData = this.listData.concat(data.info);
						} else if (data.info){
							this.listData = data.info;
						}
						if (this.pageNo == data.data_total_page) {
							this.isFinished = true;
						} else {
							this.isFinished = false;
						}
					} else {
						this.listData = [];
						this.isFinished = true;
					}
				})
			},
			onClickRight(){
				this.$router.push("/user/withdrawalCenter");
			},
		},
	}
</script>

<style scoped>
	/* .van-nav-bar--fixed,.Site .van-nav-bar{
	background-color: #0f25fe;
} */
	.PageBox {
		overflow: auto;
	}

	.PageBox .panel{
		width: 95%;
		margin: 0 auto;
	}
	.panelItem{
		margin-left: 10px;
		padding: 5px;
		width: 95%;

	}
	.van-cell{
		border-radius: 5px;
		margin-top: 10px;

	}
	.FundItem {
		margin-top: 10px;
    background-color: var(--panel_color);
    padding-left: 10px;
		border-radius: 10px;
	}
	.topTitle {
		position: absolute;
		max-width: 70%;
		white-space: nowrap;
		font-size: 18px;
		background-color: var(--panel_color);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 9999;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
	}

	.PageBox>>>.mytable {
		background-color: #FFFFFF;
		width: 91%;
		margin: 0 auto;
		margin-top: 6px;
		font-size: 18px;
	}

	.PageBox>>>table {
		width: 100%;
		border: none;
		/* border-spacing: 1px; */
		border-collapse: collapse;
	}

	.PageBox>>>th:nth-child(1) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 26%;
	}

	.PageBox>>>th:nth-child(2) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 24%px;
	}

	.PageBox>>>th:nth-child(3) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 24%;
	}

	.PageBox>>>th:nth-child(4) {
		background-color: #b3b5fc;
		font-weight: 600;
		font-size: 13px;
		/* padding: 6px 0px; */
		border: none;
		height: 33px;
		width: 26%;
	}

	.PageBox>>>th {
		border-right: 1px solid #FFFFFF !important;
	}

	.PageBox>>>table tr td {
		font-size: 11px;
		color: #626262;
		padding: 8px 0px;
		text-align: center;
		border: none;
		border-right: 1px solid #FFFFFF !important;
	}

	table tbody tr:nth-child(odd) {
		background: #eff0f2;
	}

	table tbody tr:nth-child(even) {
		background: #e1e1f9;
	}

	.statusBlock {
		color:#FFFFFF;
		text-align: center;
		margin: 0 auto;
		padding: 5px;
		width: 110px;
		border-bottom-left-radius: 10px;
	}
	/* .PageBox >>> .mytable {
	  background: #eff0f2;
	  width: 90%;
	  margin: 0 auto;
	  margin-top: 20px;
	}
	.PageBox >>> table {
	  width: 100%;
	  margin: 0 5% 0 5%;
	  padding-bottom: 20px;
	  border-spacing: 0;
	}
	.PageBox >>> th {
	  background: #f5f6ff;
	  font-weight: 700;
	  font-size: 18px;
	  position: relative;
	}
	.PageBox >>> th:nth-of-type(5) {
	  border: none;
	}
	.PageBox >>> .tabHead div {
	  height: 12px;
	  width: 1px;
	  background: #b9babd;
	  position: absolute;
	  left: 100%;
	  top: 10px;
	  z-index: 10;
	}
	.PageBox >>> table tr td {
	  height: 41px;
	  color: #626262;
	  font-size: 15.78px
	} */
</style>
